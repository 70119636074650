.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.pick-class-label {
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

.navbar-solid {
  background-color: rgb(174, 204, 232) ; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

#icons-row i.now-ui-icons {
  font-size: 30px;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
  height: 100%;
}

#notifications {
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn {
  margin: 0 0px 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #FFFFFF;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

.section-navbars {
  padding-top: 0;
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
  pointer-events: none;
}

.section-tabs {
  background: #EEEEEE;
}

.section-pagination {
  padding-bottom: 0;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a+a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.components-page .title,
.tutorial-page .title {
  color: #FFFFFF;
}

.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .h2-seo {
  font-size: 2.8em;
  color:#51CBCE
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}
.nav-logo {
  max-width: 175px;
  height: auto;
}

.nav-logo2 {
  max-width: 75px;
  height: auto;
}
.p-navbar {
  font-size: 100px;
}
.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-basic {
  padding-top: 0;
}

.section-images {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .section-navbars .navbar-collapse {
    display: none !important;
  }
}

.react-calendar {
  width: 50%; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  background: #f5f5f5;
  border: 0px solid #ccc;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.page-container {
  display: flex;
  justify-content: center; /* Center the overall container horizontally */
  align-items: center;
}

.calendar-container {
  display: flex;
  flex-direction: column; /* Arrange children in a row */
  align-items: center; /* Center items vertically within the container */
  justify-content: flex-start; /* Align items to the start */
  gap: 10px; /* Add some space between the calendar and the price container */
  width: 80%; /* Adjust width to move the container towards the left */
  padding-bottom: 120px;
}
.calendar-container p {
  margin: 0; /* Remove default margin */
  font-weight: normal; /* Make text bold */
  font-style: italic ;
  width: 50%;
}
.price-container {
  display: flex;
  flex-direction: row; /* Arrange children in a row */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Distribute items evenly, with space between */
  width: 50%; /* Take full width of the container */
}

.price-container p {
  margin: 0; /* Remove default margin */
  font-weight: bold; /* Make text bold */
  font-style: normal;
  color:black
}
.h2-space {
  margin-bottom: 2vh;/* Remove default margin */
}
.h2-detalles {
  margin: 0; 
  margin-bottom: 1vh;/* Remove default margin */
  font-weight: bold; /* Make text bold */
  font-style: normal;
  color: black;
}
.p-precio {
  margin: 0; /* Remove default margin */
  font-weight: bold; /* Make text bold */
  font-style: normal;
  color: black;
}
.unavailable-date {
  background-color: rgba(255, 0, 0, 0.3); /* Mild transparent red */
  pointer-events: none; /* Optional: Prevent clicking on the date */
}

.loader_refresh {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.section-padding{
  margin-bottom: 20vh;
}

.p-sidenote {
  margin: 0; /* Remove default margin */
  font-style: cursive;
  color:black
}

.confetti {
  transition: opacity 0.5s ease-out;
}

.fade-out {
  opacity: 0;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000; /* Ensure the button is above other elements */
}

.whatsapp-button img {
  width: 35px;
  height: 35px;
}
.footer-home {
  position: relative;
  width: 100%;
  bottom: 0;
  padding: 24px 0;
}
.footer-new {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 10px 0;
}

@media (max-width: 1210px) {
  .react-calendar {
    width: 120%; /* Adjust the width as needed */
    height: 100%; /* Adjust the height as needed */
    margin-top: 0px;
    background: #f5f5f5;
    border: 0px solid #ccc;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .price-container {
    width: 120%;
    font-size: 1.3em;
  }
  .calendar-container p {
    width: 120%;
    font-size: 0.85em;
  }
  .h2-detalles {
    margin-top: 15vh; /* Remove default margin */
    font-weight: bold; /* Make text bold */
    font-style: normal;
    color: black;
  }
}

.react-calendar__navigation {
  display: flex;
  height: 50px;
  margin-bottom: 1em;
  background: #e5e5e5;
}

.react-calendar__navigation button {
  background-color: #aecce8; /* Change the background color */
  color: black; /* Change the text color */
  border-radius: 5px; /* Add rounded corners */
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  width: 50px; /* Increase the width */
  height: 50px; /* Increase the height */
  
}
.react-calendar__month-view__days__day {
  font-size: 1em !important; /* Increase the font size */
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 1.1em !important; /* Increase the font size */
}


.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  color: #333;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 35px 10px; /* size of each tile */
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  color: #333;
}

.react-calendar__tile:disabled {
  background-color: rgba(182, 59, 59, 0.3);
  color: #ababab;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #d4e6d4;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: grey;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #aecce8;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* Login CSS Part */
.card-login {
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.input-group-lg {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 10px;
}

.btn-round {
  border-radius: 30px;
  font-size: 16px;
  padding: 10px 20px;
}

.p-precio {
  font-weight: bold;
  margin-bottom: 10px;
}

.p-sidenote {
  font-size: 18px;
  color: #000000;
}

/* Input group alignment */
.input-group-lg {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 5px 10px;
  align-items: center; /* Ensures vertical alignment */
}

/* Input group icon alignment */
.input-group-text {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

/* Icon styling */
.input-group-text i {
  font-size: 18px; /* Adjust icon size */
  color: #666;
  margin-right: 10px;
}

.nav-text {
  margin: 0;
  font-size: 15px; /* Ensure the text size matches the button */
}